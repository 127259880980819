import {Box,ListItem,List,ListItemText,ListItemIcon, Paper, Typography, Grid, CardMedia } from "@mui/material";
import {LocalAirport} from '@mui/icons-material';
import './Product.css';


const services = [
    {
        "serviceTitle": "Exclusive ToT for MSCN,",
    },
    {
        "serviceTitle": "Only MSCN that passed the QT / Field Trials.",
    },
    {
        "serviceTitle": "Plant operational at Delhi. ",
    },
    {
        "serviceTitle": "Sub-Vendor support established.",
    },
    {
        "serviceTitle": "First MSCN-based Shelter Contract on the anvil.",
    },
    {
        "serviceTitle": "Collaboration with CSIR-CBRI & CSIR-CSIO.",
    },
]

function Camouflage() {
    return (
      <div className="service-section">
        <Paper elevation={6}>
        <Typography color="inherit" align="center" variant="h4" marked="center" style={{paddingTop: '30px', paddingBottom: '30px'}}>
            Multi-Spectral Camouflage Systems
        </Typography>
        <Box p={1} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}> 
                <CardMedia
                    component="img"
                    className='product-card-image'
                    image={'/service-images/camouflage.jpg'}/>
                </Grid>
                <Grid item md={7} xs={12}> 
                    <Typography variant="h5"><List>
                        {services.map((service, index) => {
                                return (
                            <ListItem key={index}>
                            <ListItemIcon>
                                <LocalAirport/>
                            </ListItemIcon>
                            <ListItemText primary={service['serviceTitle']}
                            />
                            </ListItem>);
                        })
                        }
                        </List></Typography>
                </Grid>
            </Grid>
        </Box>
      </Paper>          
      </div>
    );
}

export default Camouflage;