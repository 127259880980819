import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './Product.css';


export default function ProductCard({ imageSrc, productTitle, productDesc }) {
  return (
    <Card>
      <CardMedia
        component="img"
        className='product-card-image'
        image={imageSrc}/>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {productTitle}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {productDesc}
        </Typography>
      </CardContent>
    </Card>
  );
}