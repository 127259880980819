import {Box,ListItem,List,ListItemText,ListItemIcon, Paper, Typography } from "@mui/material";
import {LocalAirport} from '@mui/icons-material';
import './Service.css';


const services = [
    {
        "imageSrc": "/service-images/headup-display.png",
        "serviceTitle": "Circuit Design and PCB Layout Design" ,       
        "serviceDesc": "Our Team Head has more than three decades of experience in the domain and has designed many advanced versions of the HUDs. Smart Head-up Display (SHUD) shows digitally synthesized graphical information and camera image on the pilot’s FoV. The device is enabled with a contemporary matrix image element system, precision projection optics based on aspheric lenses, and large MTBF that significantly reduces workload, and fatigue to enhance ease of operation and augment the pilot’s combat efficiency."
    },
    {
        "imageSrc": "/service-images/up-front-control-panel.png",
        "serviceTitle": "Design Analysis",
        "serviceDesc": "A self-contained MIL-STD-704D, 461C, 810 compliant LRU enabled with an alphanumeric display (88mm x 32mm) laid out in 5 rows of 24 characters and RS-422 Serial Link with MC. The GLASS UFCP technology provides scalable and reconfigurable solutions."
    },
    {
        "imageSrc": "/service-images/smfd.png",
        "serviceTitle":  "PCB Assembly",
        "serviceDesc": "These ruggedized AMLCD technology-based displays afford multiple function control keys, LED  Backlighting, STANAG 3350B input video, and NVIS & NVG compliant bezel. It has a MIL-STD-1553 and ARINC 429 Interface and a MIL-STD 810D, 461C, 3009, and 704D compliant architecture."
    },
    {
        "imageSrc": "/service-images/rugged.png",
        "serviceTitle": " ATE and software",
        "serviceDesc": "Military-grade displays of multiple sizes are designed and ruggedized for top performance in a variety of severe environments. Touch sensing-enabled with customized bezel, these are MIL-STD-810G / 461E compliant."
    },
    {
        "imageSrc": "/service-images/integrated.jpg",
        "serviceTitle": "Testing and Integration" ,
        "serviceDesc": "A 3-inch standby display intended to be used in case of failure of the primary flight displays. The unit is equipped with a unit Attitude Sensor and is linked to Air Data Unit and Magnetometer sensor to provide standby functionality. The unit shares basic common technological features with SMFD."
    },
    {
        "imageSrc": "/service-images/airflow.png",
        "serviceTitle": "Thermal Design and System Packaging",
        "serviceDesc": "Confirms Angle of Attack and Side Slip Sensor onboard Serviceability. Reduces maintenance overheads, re-installation & harmonization time, and aircraft downtime. Confirms the status of the circuitry of the wiper with respect to the vane and serviceability of the body & vane heater."
    },
    {
        "imageSrc": "/service-images/airflow.png",
        "serviceTitle": "Ruggedization",
        "serviceDesc": "Confirms Angle of Attack and Side Slip Sensor onboard Serviceability. Reduces maintenance overheads, re-installation & harmonization time, and aircraft downtime. Confirms the status of the circuitry of the wiper with respect to the vane and serviceability of the body & vane heater."
    },
    {
        "imageSrc": "/service-images/airflow.png",
        "serviceTitle": "Wires and Cables",
        "serviceDesc": "Confirms Angle of Attack and Side Slip Sensor onboard Serviceability. Reduces maintenance overheads, re-installation & harmonization time, and aircraft downtime. Confirms the status of the circuitry of the wiper with respect to the vane and serviceability of the body & vane heater."
    },
]

function Hardware() {
    return (
      <div className="service-section">
        <Paper elevation={6}>
        <Typography color="inherit" align="center" variant="h4" marked="center" style={{paddingTop: '30px'}}>
            Hardware Design Services
        </Typography>
        <Typography variant="subtitle1" align = "center" gutterBottom style={{padding: '15px'}}>
           We are involved in the design and development of avionics products. As such hardware design is the core competence of the company. <br></br>Following Hardware Design Services are available in the company:
        </Typography>
        <Box p={1}>
          <Typography variant="h5"><List>
              {services.map((service, index) => {
                    return (
                <ListItem key={index}>
                  <ListItemIcon>
                    <LocalAirport />
                  </ListItemIcon>
                  <ListItemText
                    primary={service['serviceTitle']}
                  />
                </ListItem>);
            })
            }
            </List></Typography>
        </Box>
      </Paper>          
      </div>
    );
}

export default Hardware;