import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DesktopNav } from './Utils';
import { StyledLink } from '../common/StyledLink';

function ServiceNav() {
    return (
        <DesktopNav name="Services &nbsp;&nbsp;&nbsp;"
            childComponent={<ServiceNestedList/>}
            />
    );
}

export function ServiceNestedList({ listWidth }) {
    const [openMainService, setOpen] = React.useState(false);
  
    const handleMainServiceClick = (evt) => {
      evt.stopPropagation();
      setOpen(!openMainService);
    };
  
    return (
      <List
        sx={{ width: listWidth ? listWidth: 200, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={handleMainServiceClick}>
          <ListItemText primary="Main Services" />
          {openMainService ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openMainService} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
                <StyledLink to={`services/hardware`}>
                    <ListItemText primary="Hardware Design Services" />
                </StyledLink>          
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
                <StyledLink to={`services/integration`}>
                    <ListItemText primary="Integration and Commissioning" />
                </StyledLink>   
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <StyledLink to={`services/repair`}>
                <ListItemText primary="Maintenance, Repair & Overhaul" />
              </StyledLink> 
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
                <StyledLink to={`services/btp`}>
                    <ListItemText primary="Co-development & Build To Print" />
                </StyledLink>   
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
            <StyledLink to={`services/obso`}>
              <ListItemText primary="Obsolescence Management / Upgradation" />
            </StyledLink>
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    );
  }

export default ServiceNav;