import About from "./sections/About";
import Bottom from "./sections/Bottom";
import ProductHero from "./sections/ProductHero";
import ProductValues from "./sections/ProductValues";

function Home() {
    return (
      <div className="home-section">
        <ProductHero />
        <ProductValues />
        <About/>
        <Bottom />
      </div>
    );
}

export default Home;