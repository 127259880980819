import { Grid, Typography } from "@mui/material";
import ProductCard from "./ProductCard";
import './Product.css';


const products = [
    {
        "imageSrc": "/service-images/headup-display.png",
        "productTitle": "Smart Head-Up Display",
        "productDesc": "Our Team Head has more than three decades of experience in the domain and has designed many advanced versions of the HUDs. Smart Head-up Display (SHUD) shows digitally synthesized graphical information and camera image on the pilot’s FoV. The device is enabled with a contemporary matrix image element system, precision projection optics based on aspheric lenses, and large MTBF that significantly reduces workload, and fatigue to enhance ease of operation and augment the pilot’s combat efficiency."
    },
    {
        "imageSrc": "/service-images/up-front-control-panel.png",
        "productTitle": "Up Front Control Panel",
        "productDesc": "A self-contained MIL-STD-704D, 461C, 810 compliant LRU enabled with an alphanumeric display (88mm x 32mm) laid out in 5 rows of 24 characters and RS-422 Serial Link with MC. The GLASS UFCP technology provides scalable and reconfigurable solutions."
    },
    {
        "imageSrc": "/service-images/smfd.png",
        "productTitle": "Smart MultiFunction Display (SMFD)",
        "productDesc": "These ruggedized AMLCD technology-based displays afford multiple function control keys, LED  Backlighting, STANAG 3350B input video, and NVIS & NVG compliant bezel. It has a MIL-STD-1553 and ARINC 429 Interface and a MIL-STD 810D, 461C, 3009, and 704D compliant architecture."
    },
    {
        "imageSrc": "/service-images/rugged.png",
        "productTitle": "Rugged Display",
        "productDesc": "Military-grade displays of multiple sizes are designed and ruggedized for top performance in a variety of severe environments. Touch sensing-enabled with customized bezel, these are MIL-STD-810G / 461E compliant."
    },
    {
        "imageSrc": "/service-images/integrated.jpg",
        "productTitle": "Integrated Standby Instrument System (ISIS)",
        "productDesc": "A 3-inch standby display intended to be used in case of failure of the primary flight displays. The unit is equipped with a unit Attitude Sensor and is linked to Air Data Unit and Magnetometer sensor to provide standby functionality. The unit shares basic common technological features with SMFD."
    },
    {
        "imageSrc": "/service-images/airflow.png",
        "productTitle": "In-Situ Airflow Angle Sensor Tester",
        "productDesc": "Confirms Angle of Attack and Side Slip Sensor onboard Serviceability. Reduces maintenance overheads, re-installation & harmonization time, and aircraft downtime. Confirms the status of the circuitry of the wiper with respect to the vane and serviceability of the body & vane heater."
    },
]

function Avionics() {
    return (
      <div className="product-section">
        <Typography color="inherit" align="center" variant="h4" marked="center">
            Avionics & Electro-Optical Devices
        </Typography>
        <Grid container spacing={2} sx={{marginTop: 2}}>
            {
                products.map((product, index) => {
                    return (
                    <Grid item xs={12} md={4} key={index}>
                        <ProductCard
                            imageSrc={product['imageSrc']}
                            productTitle={product['productTitle']}
                            productDesc={product['productDesc']}/>
                    </Grid>);
                    })
            }
        </Grid>            
      </div>
    );
}

export default Avionics;