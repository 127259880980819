import {Paper, Typography } from "@mui/material";

function Obso() {
    return (
      <div className="service-section">
        <Paper elevation={6}>
        <Typography color="inherit" align="center" variant="h4" marked="center" style={{paddingTop: '30px'}}>
            Obsolescence Management
        </Typography>
        <Typography variant="subtitle1" align = "center" gutterBottom style={{padding: '15px'}}>
        Electronic products are increasingly designed using COTS components. The technologies of such COTS components are changing very fast to meet the constantly emerging needs of customers. As such the old technologies are becoming obsolete and phasing out at an unprecedented rate. The aircraft integrator and end users are extremely concerned with the scenario. Without a conscious and planned approach, managing obsolescence may become a tedious task. Managing obsolescence is a multipronged approach spanning over planning, supply chain, and technology management. The network spread encompassing each of these components is available for managing obsolescence.
        </Typography>
      </Paper>          
      </div>
    );
}

export default Obso;