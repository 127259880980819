import {Paper, Typography } from "@mui/material";

function Repair() {
    return (
      <div className="service-section">
        <Paper elevation={6}>
        <Typography color="inherit" align="center" variant="h4" marked="center" style={{paddingTop: '30px'}}>
            Maintainence, Repair & Overhaul
        </Typography>
        <Typography variant="subtitle1" align = "center" gutterBottom style={{padding: '15px'}}>
        The reliability and efficiency of complex systems of systems can only be ensured by regular maintenance.  While OEMs are focussed on emerging markets, new products, and the development of ongoing products, the maintenance, and repair requiring long-term engagement are left unattended. The established underlying resources for managing the MRO can be made available to the OEMs to help meet the long-term commitments of the products.  
        </Typography>
      </Paper>          
      </div>
    );
}

export default Repair;