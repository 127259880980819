import { Box } from '@mui/system';
import * as React from 'react';
import ProfileCard from '../../common/ProfileCard';
import { Typography } from '@mui/material';

const people = [
    {
        "profileName": "Amit Aggarwal",
        "imagePath": "/profile-images/amit.png",
        "shortDescription": "Director",
        "longDescription": [
            "Amit has over two decades of business experience in the family-owned Group of 4 Companies working in different verticals where he has been the Executive Director of three Companies. Amit comes with a rich experience in Roto-Gravure printing on Bopp films, metalized films, Flat Bed UV Printing, manufacturing of 3D Scaled Models, and manufacturing of Industrial HDPE Materials & Laminations. His domain expertise is guiding the Camouflage Vertical of the Company to make it a leader in the MSCN domain. Amit is committed to bringing best-in-class solutions to customers around the world. ",
            "Amit leads Customer & Account Management at Meta Tattva. He brings more than 24 years of experience to this role, where he is responsible for sales, marketing, and account management. He is leading the execution of strategic initiatives across operations, customer satisfaction compliances, and optimizing outlays & cash flow, while relentlessly working to ensure a productive & safe work environment for employees. ",
            "Amit earned his Bachelor of Science Degree from Lucknow.  He also holds a Master's degree in Business Administration."
        ]
    },
    {
       "profileName": "Praveen Bhatt",
       "imagePath": "/profile-images/praveen.png",
       "shortDescription": "Director",
       "longDescription": [
           ""
       ]
    },
    {
       "profileName": "Poonam Bhatt",
       "imagePath": "/profile-images/poonam.png",
       "shortDescription": "Director",
       "longDescription": [
           ""
       ]
    }
]


export default function About() {
  return (
    <Box>
        <Typography variant="h4" sx={{ my: 5 }}>
            Our Team
        </Typography>
        <Box display="flex" justifyContent="center" flexWrap="wrap"  p={2}>
        {people.map((data, index) => {
            return <ProfileCard 
                key={index}
                picturePath={data['imagePath']}
                profileName={data['profileName']}
                shortDescription={data['shortDescription']}
                longDescription={data['longDescription']}
            />
        })}
    </Box>
    </Box>
    
  );
}
