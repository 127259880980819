import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { Box, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './Profile.css';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    boxShadow: 24,
    p: 4
  };

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: '100px', 
  height: '100px',
  '& img': {
    objectFit: 'contain'
  }
}));

export default function ProfileCard({
    picturePath, profileName, shortDescription, longDescription
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ minWidth: 345,  margin: "15px" }}>
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <StyledAvatar
                    alt="Display picture"
                    src={picturePath}
                />
        </Box>

      <CardContent>
       {profileName}
        <Typography variant="body2" color="text.secondary">
          {shortDescription}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more">
          <ReadMoreIcon />
        </ExpandMore>
      </CardActions>
      <Modal
          open={expanded}
          onClose={handleExpandClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modalStyle} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box display="flex" justifyContent="right" alignItems="right" width="100%">
              <CloseIcon onClick={handleExpandClick}/>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                <StyledAvatar
                        alt="Display picture"
                        src={picturePath}/>
            </Box>
            <Typography variant="h6">
              {profileName}
            </Typography>
            <div className='modal-body-content'>
                {longDescription.map((desc, index) => {
                  return <p key={index}>{desc}</p>
                })}
            </div>
          </Box>
      </Modal>
    </Card>
  );
}
