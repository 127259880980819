import { List, ListItemButton, ListItemText } from "@mui/material";
import { StyledLink } from '../common/StyledLink';
import { DesktopNav } from "./Utils";

function ProductNav() {
    return (
        <DesktopNav name="Products &nbsp;&nbsp;&nbsp;"
            childComponent={<ProductNestedList/>}
        />
    );
}

export function ProductNestedList({ listWidth }) {
    return (
        <List
            sx={{ width: listWidth ? listWidth: 200, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader">
            <ListItemButton>
                <StyledLink to={`products/camouflage`}>
                    <ListItemText primary="Multi-Spectral Camouflage Systems" />
                </StyledLink>
            </ListItemButton>
            <ListItemButton>
                <StyledLink to={`products/supply`}>
                    <ListItemText primary="Aviation Component Parts Supply Chain Solutions" />
                </StyledLink>
            </ListItemButton>
            <ListItemButton>
                <StyledLink to={`products/avionics`}>
                    <ListItemText primary="Avionics" />
                </StyledLink>
            </ListItemButton>
        </List>
    )
}


export default ProductNav;