import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import './Section.css';

export default function ProductHero() {
  return (
    <div className='product-section'>
      <Typography color="inherit" align="center" variant="h2" marked="center">
        <img src="/images/logo.png" height="150px" alt='logo'/>
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div><img src="/images/plane.png" height="130px" alt='plane'/></div>
          <Typography
            color="inherit"
            align="center"
            padding="5px 20px"
            sx={{ mb: 4, mt: { sx: 2, sm: 4 } }}>
            The core strength of Meta Tattva Systems Pvt Ltd is its highly inspired and experienced professionals who are engaged in indigenously developing state-of-the-art technology solutions using applied research and cross-over innovations. 
            We build advanced systems for high-end defense applications such as avionics & other airborne electro-optical displays, sensors, and control systems. 
            The IDDM technology being core to its product creation, the company services the entire lifecycle of the product from its design, development, manufacturing, testing, qualification, and repair & maintenance. 
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            color="inherit"
            align="center"
            padding="5px 20px"
            sx={{ mb: 4, mt: { sx: 2, sm: 4 } }}
          >
            <br/>
            Our competencies focussed on advanced technologies offers opportunities to address obsolescence management of defense systems to extend their life or replace them with economical and reliable cutting-edge technology-supported systems.
            <br/><br/>
            We believe that in our relentless pursuit of excellence, our coherent scientific approach, productive partnerships, and collective prowess would afford us limitless possibilities in the realm of our mutually shared objectives with our partners and customers. The path that we together pave leads us to new horizons.
            The trust that we earn from our customers inspires us every day to break the frontiers of the cutting-edge technology
          </Typography>
          <Typography variant="h7" color="inherit" sx={{ mt: 2 }}>
            <b>WE ARE IN THE BUSINESS OF REDEFINING AVIATION TECHNOLOGY</b>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}