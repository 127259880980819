import { ExpandMore } from "@mui/icons-material";
import { Select} from "@mui/material";
import { styled } from "@mui/system";


const commonStyle = {
    padding: "0px",
    color: 'white',
    position: 'absolute',
    right: '25px',
    zIndex: '-1'
}

export const StyledExpandMore = styled(ExpandMore)(() => (commonStyle));


export function DesktopNav({name, childComponent}) {
    return (
        <Select
          className="navbar-select-box"
          displayEmpty
          renderValue={() => {
            return <div className="navbar-placeholder hover-underline-animation">{name}</div>;
            }
          }
          MenuProps={{ disableScrollLock: true }}
          IconComponent={() => (<StyledExpandMore/>)}
          inputProps={{ 'aria-label': 'Without label' }}>
            {childComponent}
        </Select>
    );
}