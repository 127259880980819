import {Box,ListItem,List,ListItemText,ListItemIcon, Paper, Typography } from "@mui/material";
import {LocalAirport} from '@mui/icons-material';
import './Service.css';


const services = [
    {
        "serviceTitle": "The project’s success depends upon the strength of organizations and the proper utilization of resources. For multisite projects, reliable partners can make a significant difference in project execution.",
    },
    {
        "serviceTitle": "We can help any OEM to offload part of the project to us resulting in co-development.",
    },
    {
        "serviceTitle": "The BTP of a product is rendered by an experienced team having BTP execution experience with global avionics majors.",
    },
    {
        "serviceTitle": "The established supply chain, production processes, and quality control practices offer quick implementation of BTP and reliable delivery to customers.",
    },
]

function Btp() {
    return (
      <div className="service-section">
        <Paper elevation={6}>
        <Typography color="inherit" align="center" variant="h4" marked="center" style={{paddingTop: '30px', paddingBottom: '30px'}}>
            Co-development & Build To Print (BTP)
        </Typography>
        <Box p={1}>
          <Typography variant="h5"><List>
              {services.map((service, index) => {
                    return (
                <ListItem key={index}>
                  <ListItemIcon>
                    <LocalAirport/>
                  </ListItemIcon>
                  <ListItemText primary={service['serviceTitle']}
                  />
                </ListItem>);
            })
            }
            </List></Typography>
        </Box>
      </Paper>          
      </div>
    );
}

export default Btp;