import {Box,ListItem,List,ListItemText,ListItemIcon, Paper, Typography } from "@mui/material";
import {LocalAirport} from '@mui/icons-material';
import './Service.css';


const services = [
    {
        "serviceTitle": "Integration of Prototype System into the larger context (RIG or Aircraft).",
    },
    {
        "serviceTitle": "Qualification Testing.",
    },
    {
        "serviceTitle": "Integration for the Field Trials for the Type approval.",
    },
    {
        "serviceTitle": "Production and commissioning of the project.",
    },
]

function Integration() {
    return (
      <div className="service-section">
        <Paper elevation={6}>
        <Typography color="inherit" align="center" variant="h4" marked="center" style={{paddingTop: '30px'}}>
            Integration & Commissioning
        </Typography>
        <Typography variant="subtitle1" align = "center" gutterBottom style={{padding: '15px'}}>
            We have the desired expertise to accomplish and help the OEMs execute the integration and commissioning of their products.
        </Typography>
        <Box p={1}>
          <Typography variant="h5"><List>
              {services.map((service, index) => {
                    return (
                <ListItem key={index}>
                  <ListItemIcon>
                    <LocalAirport/>
                  </ListItemIcon>
                  <ListItemText primary={service['serviceTitle']}
                  />
                </ListItem>);
            })
            }
            </List></Typography>
        </Box>
      </Paper>          
      </div>
    );
}

export default Integration;