import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { List, ListItem, Typography } from '@mui/material';


const BottomStyledBox = styled(Paper)(({ theme }) => ({
    backgroundColor: '#eff1f4',
    ...theme.typography.body2,
    padding: theme.spacing(10),
    marginTop: "10px",  
    textAlign: 'center',
    color: "#344767",
  }));


const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: "0px",
    marginTop: "0px",  
    textAlign: 'left',
    color: "#344767",
}));


export default function Bottom() {
  
  return (
  <BottomStyledBox sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
      <Grid xs={12} md={3}>
      <img src="/images/logo.png" height="100px" alt="logo"/>
      </Grid>
      <Grid xs={12} md={3} textAlign="left">
        <Typography>Office Address</Typography>
        <List>
            <StyledListItem>B-101, Winter Hills Apartment Uttam Nagar</StyledListItem>
            <StyledListItem>New Delhi - 110059 </StyledListItem>
            <StyledListItem>India </StyledListItem>
        </List>
      </Grid>
      <Grid xs={6} md={2} textAlign="left">
        <Typography>Contact Us</Typography>
        <List>
            <StyledListItem>metatattva@gmail.com</StyledListItem>
        </List>
      </Grid>
      <Grid xs={6} md={2}>
        
      </Grid>
    </Grid>
  </BottomStyledBox>
  );
}