import {Box,Paper, Typography, Grid, CardMedia } from "@mui/material";

function Supply() {
    return (
      <div className="service-section">
        <Paper elevation={6}>
        <Typography color="inherit" align="center" variant="h4" marked="center" style={{paddingTop: '30px', paddingBottom: '30px'}}>
            Aviation Component Parts Supply Chain Solutions
        </Typography>
        <Box p={1} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={5} xs={12}> 
                <CardMedia
                    component="img"
                    className='product-card-image'
                    image={'/service-images/aviation-plane.png'}/>
                </Grid>
                <Grid item md={7} xs={12}> 
                    <Typography variant="subtitle1" align = "center" style={{paddingTop: '30px', paddingBottom: '30px'}}>
                    Transforming efficiency in commercial, business, and defense aviation, supporting scheduled airlines and non-scheduled charter air carrier.<br></br>

By establishing mutually beneficial trusted and established partnerships to transform operational efficiency, we utilize our trusted and established vendor supply chain relationships to lead the industry by enabling cost savings and increasing efficiency for customers.

                        </Typography>
                </Grid>
            </Grid>
        </Box>
      </Paper>          
      </div>
    );
}

export default Supply;
