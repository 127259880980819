import { styled } from "@mui/system";
import { Link } from "react-router-dom";


export const StyledLink = styled(Link)(({ color }) => ({
    padding: "0px",
    marginTop: "0px",  
    textAlign: 'left',
    color: color ? color : "#344767",
    textDecoration: 'none'
}));